@font-face {
  font-family: 'Rag Marom';
  src: url('https://assets.website-files.com/65467772df5823449b650671/65467772df5823449b6506e1_RAG-Marom%201.0.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.cdnfonts.com/css/droid-sans-2');

:root {
  /* fonts */
  --font-main: 'Rag Marom';
  --font-secondary: 'var(--font-secondary)', sans-serif;

  /* Colors */
  --white: #fff;
  --red: #d63e22;
  --gray: #444444;
  --light-gray: #a9a9a9;
  --black: #000;

  /* Border radiuses */
  --br: 30px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

body {
  margin: 0;
  line-height: normal;

  font-family: var(--font-main);
  font-size: 16px;
}

.actions {
  display: inline-block;
  padding-top: 10px;
  width: 300px;
  text-align: center;
  margin-bottom: 20px;
}

.actions .i-called {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 24px;
  position: relative;
  background-color: #000000;
  border-radius: 30px;
  cursor: pointer;
}

.actions .i-called .check {
  position: relative;
  width: 14px;
  height: 14px;
}

.actions .i-called .text {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Droid Sans', Helvetica;
  font-weight: 400;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.actions .remind-me {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 24px;
  position: relative;
  cursor: pointer;
}

.actions .remind-me .text {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: 'Droid Sans', Helvetica;
  font-weight: 400;
  color: #000;
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}
